import React from "react";
import useGetAWebPage from "../../hooks/web-pages/useGetAPage";

function WebPageDetail({ id }) {
  const { page } = useGetAWebPage(id);

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: page,
      }}
    ></span>
  );
}

export default WebPageDetail;
