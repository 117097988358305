import useSWR from "swr";
import Api from "../../services/Api";

function useGetAWebPage(id) {
  const getKey = () => {
    return `${process.env.NETLIFY_API_BASE_URL}?endpoint=pages/${id}`;
  };

  const { data, error } = useSWR(() => getKey(), getPage);

  const isLoading = !data && !error;
  const page = data?.body || "";

  return {
    page,
    isLoading,
  };
}

async function getPage(url) {
  const response = await Api.get(url);
  return response.data;
}

export default useGetAWebPage;
