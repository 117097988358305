import { Link, graphql } from "gatsby";
import React from "react";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
import Layout from "../layout/Layout";
import WebPageDetail from "../components/webpage/WebPageDetail";

function webPages({
  data: {
    allGetWebPages: { edges },
  },
  location,
}) {
  const page = edges.filter(({ node }) => {
    return node && node.url && node.url.includes(location.pathname);
  });

  const subPages = edges.filter(({ node }) => {
    if (node && node.parent_id && page && page.lenght > 0)
      return node.parent_id === page[0].node.bigcommerce_id;
    else return false;
  });

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center">
          <BreadCrumbs location={location} />
          <h1 className="page-title">
            {page && page.lenght > 0 && page[0].node.name}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row flex flex-wrap page-layout">
          <div className="col page-sidebar">
            <strong>{page && page.lenght > 0 && page[0].node.name}</strong>
            <ul>
              {subPages.map((i) => (
                <li key={i.node.id}>
                  <Link to={i.node.url}>{i.node.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col page-content">
            <WebPageDetail
              id={page && page.length && page[0].node.bigcommerce_id}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const webPagePageQuery = graphql`
  query WebPage {
    allGetWebPages(filter: { is_visible: { eq: true } }) {
      edges {
        node {
          id
          name
          bigcommerce_id
          url
          parent_id
        }
      }
    }
  }
`;

export default webPages;
